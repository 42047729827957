import * as FiIcons from "react-icons/fi";
import routes from "../../../navigations/routes";
import { IoBarChartSharp } from "react-icons/io5";
import { IoPersonSharp } from "react-icons/io5";
import { TbReport } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { FcOvertime } from "react-icons/fc";
import { FaArrowsDownToPeople } from "react-icons/fa6";
import { GiMoneyStack } from "react-icons/gi";
import { IoMdArrowRoundBack } from "react-icons/io";

const Hrlinks = [
  {
    text: "pages.hr.hr_dashboard",
    path: routes.dashboardHr,
    icon: <IoBarChartSharp size={18} />,
    subLinks: null,
  },

  // {
  //   text:"pages.hr.My_Tasks",
  //   path:routes.MyTasks,
  // },

  {
    text: "pages.hr.Employees",
    path: routes.Employees,
    subLinks: null,
    icon: <IoPersonSharp size={18} />,
  },
  {
    text: "pages.hr.LeaveReports",
    path: routes.LeaveReports,
    subLinks: null,
    icon: <TbReport size={18} />,
  },
  {
    text: "pages.hr.today_attendance",
    path: routes.TodayAttendance,
    subLinks: null,
    icon: <TbReport size={18} />,
  },
  {
    text: "pages.hr.attendance_by_date",
    path: routes.addendanceByDate,
    subLinks: null,
    icon: <TbReport size={18} />,
  },
  {
    text: "pages.hr.Attendance_settings",
    path: routes.AttendanceSettings,
    subLinks: null,
    icon: <IoSettingsOutline size={18} />,
  },

  {
    text: "pages.hr.overTime",
    path: routes.overTime,
    subLinks: null,
    icon: <FcOvertime size={18} />,
  },
  {
    text: "pages.hr.Positions",
    path: routes.Positions,
    subLinks: null,
    icon: <FaArrowsDownToPeople size={18} />,
  },
  {
    text: "pages.hr.Official_Vacation_List",
    path: routes.VacationList,
    subLinks: null,
    icon: <TbReport size={18} />,
  },

  {
    text: "pages.hr.Payroll",
    path: routes.Payroll,
    subLinks: null,
    icon: <GiMoneyStack size={18} />,
  },
  {
    text: "pages.hr.back",
    icon: <IoMdArrowRoundBack size={18} />,
    subLinks: null,
    path: routes.dashboard_general,
  },
];

export default Hrlinks;