import axios from "axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");

// /* -------------------  Get Positions List ---------------------*/
export const getPositionsList = createAsyncThunk(
  "positions/getPositionsList",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/PositionList`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

// /* -------------------  Get Department Dropdown ---------------------*/
export const getDepartmentDropdown = createAsyncThunk(
  "positions/getDepartmentDropdown",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Department/LookupDepartment`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Get Job Title Dropdown ---------------------*/
export const getJobTitleDropdown = createAsyncThunk(
  "positions/getJobTitleDropdown",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/JobTitle/LookupJobTitle`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Get Positions Dropdown ---------------------*/
export const getPositionDropdown = createAsyncThunk(
  "positions/getPositionDropdown",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Position/LookupPosition`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Get Details By Id ---------------------*/
export const getPositionDetailsById = createAsyncThunk(
  "positions/getPositionDetailsById",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetPositionById?positionid={"PositionId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

// /* ------------------  Edit Position  ---------------------*/
export const editPositionById = createAsyncThunk(
  "positions/editPositionById",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Position/EditPosition?positionid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------  Deleted Positions ---------------------*/
export const deletePositionById = createAsyncThunk(
  "positions/deletePositionById",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Position/DeletePositionById?positionid={"PositionId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
