import {
  Box,
  Checkbox,
  Td,
  Tr,
  Thead,
  Table,
  Th,
  Tbody,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getGroupShiftDetails } from "../services/attendanceSettingServices";
import { useDispatch } from "react-redux";

export default function GroupshiftDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

useEffect(() => {
  // dispatch(getGroupShiftDetails());
}, [])

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>{t("pages.hr.attendance.days")}</Th>
            <Th>{t("pages.hr.attendance.is_vacation")}</Th>
            <Th>{t("pages.hr.attendance.time_code")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* {days.map((item, index) => (
            <Tr key={index}>
              <Td>{item.Time_Day}</Td>
              <Td>
                <Checkbox isChecked={item.IS_Vacation}>Is Vacation</Checkbox>
              </Td>
            </Tr>
          ))} */}
        </Tbody>
      </Table>
    </Box>
  );
}
