import React from "react";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function AbsenceDays({ absenceList }) {
  const { t } = useTranslation();

  return (
    <>
      <Table>
        <Thead bg={"blue.500"}>
          <Tr>
            <Th color={"white"}>#</Th>
            <Th color={"white"}>{t("pages.hr.Employee_code")}</Th>
            <Th color={"white"}>{t("pages.hr.Employee_name")}</Th>
            <Th color={"white"}>{t("pages.hr.job_title")}</Th>
            <Th color={"white"}>{t("pages.hr.employee_department")}</Th>
            <Th color={"white"}>{t("pages.hr.date")}</Th>
          </Tr>
        </Thead>

        {absenceList?.length > 0 ? (
          <Tbody>
            {absenceList?.map((item, index) => (
              <Tr key={index}>
                <Td> {index + 1} </Td>
                <Td>{item?.Code}</Td>
                <Td>{item?.Name}</Td>
                <Td>{item?.Title}</Td>
                <Td>{item?.Departmen}</Td>
                <Td>{item?.Date}</Td>
              </Tr>
            ))}
          </Tbody>
        ) : (
          ""
        )}
      </Table>

      {absenceList?.length === 0 && (
        <Text
          fontSize="lg"
          fontWeight={"bold"}
          color="blue.600"
          padding={"20px"}
          textAlign={"center"}
        >
          "No Days Matching Date"
        </Text>
      )}
    </>
  );
}
