import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { getListinventoryitem } from "../services/inventoryCountService"
import CreateTransferOrdersModal from '../components/modals/CreateInventoryCountModal'
import InventoryCountTable from '../components/InventoryCountTable';
import ExportModal from 'components/ui/modal/ExportModal';

import { checkIdExists } from "utils/cheackAuth";
import useDecryptedPrivilege from "hooks/useDecryptedPrivilege "; 

const InventoryCount = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch()
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const [CreateTranOrdersModalstate, setCreateTranOrdersModalstate] = useState(false);
  const [exportModal,SetExportModal]=useState(false);

  const items = useSelector(state => state.inventoryCount);

  useEffect(() => {
    dispatch(getListinventoryitem())
  }, [dispatch]);

  /* -----------------------------------Privilige-------------------------------*/
  const retrievedArray =useDecryptedPrivilege();

  const ManageInventoryCount = checkIdExists(retrievedArray, 31);

  if(CreateTranOrdersModalstate === true && ManageInventoryCount === false){
    toast2({ description:  t("general.authorization")});
    setCreateTranOrdersModalstate(false);
  };
/* -----------------------------------Privilige-------------------------------*/  

  return (
    <div>
      <Box
        bg="light" marginInline={-6} marginTop={-6} padding={6}
        borderTop="1px solid" borderColor="border" marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
          >
            {t("layout.sidebar.inventory_count")}
          </Text>

          <Flex gap={2}>

              <Button
                type="button" bg="none" border="1px solid" borderColor="border"
                textTransform="capitalize" fontSize="1rem" color="text"
                _hover={{ bg: "primary", color: "light" }}
                onClick={() => SetExportModal(true)}
              >
                  <Text>{t("general.export")}</Text>
              </Button>
              <Button
                type="button" bg="primary" color="light"
                textTransform="capitalize" fontSize="1rem"
                border="1px solid transparent"
                _hover={{ bg: "none", color: "primary", borderColor: "primary" }}
                onClick={() => setCreateTranOrdersModalstate(true)}
              >
                <Flex alignItems="center" gap={1} >
                  {t("pages.inventory_count.new_inventory_count")}
                </Flex>
              </Button>
          </Flex>
        </Flex>
      </Box>

      {
        items.isLoading ? (
          <Flex bg="light" p={4} justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <InventoryCountTable  filteredData={items.filteredData} data={items.data}></InventoryCountTable>
        )
      }

      {CreateTranOrdersModalstate && (
        <CreateTransferOrdersModal onClose={() => setCreateTranOrdersModalstate(false)} />
      )}
      {exportModal&&(
          <ExportModal 
            onClose={() => SetExportModal(false)}   
            data={items.data}
            fileName={"inventory_count"}
            >
          </ExportModal>
      )}

    </div>
  );
}

export default InventoryCount