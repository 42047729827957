import axios from "axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");

/* -------------------  Get Classification ---------------------*/
// export const getEmployeeList = createAsyncThunk(
//   "generalEmployee/getEmployeeList",
//   async (args, thunkAPI) => {
//     const { rejectWithValue, fulfillWithValue } = thunkAPI;
//     const { data } = await axios.get(
//       `https://rostoapi.smartgate-egypt.com/Hr/Employee/EmployeeList`
//     );
//     try {
//       return fulfillWithValue(data);
//     } catch (e) {
//       return rejectWithValue(e.response.data);
//     }
//   }
// );

/* ----------------------------------  Get Insurance Office Dropdown  ---------------------------------------*/
export const getInsuranceOffice = createAsyncThunk(
  "generalEmployee/getInsuranceOffice",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/LookupInsuranceOffice`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
