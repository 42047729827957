// import React from "react";
// import {
//   Box,
//   Flex,
//   Table,
//   Tbody,
//   Td,
//   Th,
//   Thead,
//   Tr,
//   Checkbox,
// } from "@chakra-ui/react";
// import * as FiIcons from "react-icons/fi";
// import GButton from "components/ui/button/Button";
// import { useTranslation } from "react-i18next";

// const LeaveReportsTable = ({ data }) => {
//   const { t } = useTranslation();

//   return (
//     <Box
//       bg="light"
//       overflow="auto"
//       borderRadius={4}
//       boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
//     >
//       <Flex
//         flexWrap="wrap"
//         justifyContent="space-between"
//         paddingBlock={2}
//         p={4}
//         borderBottom="1px solid"
//         borderColor="border"
//         bg="light"
//         gap={2}
//       >
//         <Flex
//           alignItems="center"
//           flexWrap="wrap"
//           textTransform="capitalize"
//           gap={4}
//           color="text"
//           bg="light"
//         >
//           <GButton
//             title={t("general.all")}
//             bg="none"
//             isDisabled={false}
//             borderRadius={0}
//             borderColor="primary"
//           />
//         </Flex>

//         <GButton
//           title={t("general.filter")}
//           border="1px solid"
//           borderColor="border"
//           postfixElement={<FiIcons.FiFilter size={16} />}
//           hover={{ bg: "primary", borderColor: "primary", color: "light" }}
//           //onClick={() => setShowFilterCategoriesModal(true)}
//         />
//       </Flex>

//       <Table>
//         <Thead>
//           <Tr>
//             <Th>{t("pages.hr.code")}</Th>
//             <Th>{t("pages.hr.name")}</Th>
//             <Th>{t("pages.hr.leave")}</Th>

//             <Th>{t("pages.hr.carryOver")}</Th>
//             <Th>{t("pages.hr.annual_entitled")}</Th>
//             <Th>{t("pages.hr.opening_balance")}</Th>
//             <Th>{t("pages.hr.colosed_balance")}</Th>
//             <Th>{t("pages.hr.department")}</Th>
//             <Th>{t("pages.hr.remaining")}</Th>
//             <Th>{t("pages.hr.consumed")}</Th>
//           </Tr>
//         </Thead>
//         <Tbody>
//           {data.map((el) => {
//             return (
//               <Tr>
//                 <Td> {el?.EmployeeCode} </Td>
//                 <Td> {el?.FullEnName} </Td>
//                 <Td> {el?.VacationTypeName} </Td>
//                 <Td> {el?.CarryOverBalance} </Td>

//                 <Td> {el?.CurrentEntitled} </Td>
//                 <Td> {el?.CurrentOpening} </Td>
//                 <Td> {el?.cruntmonthBalance} </Td>
//                 <Td> {el?.annualBlance} </Td>
//                 <Td> {el?.YtDConsumed} </Td>
//               </Tr>
//             );
//           })}
//         </Tbody>
//       </Table>
//     </Box>
//   );
// };

// export default LeaveReportsTable;

import React, { useState } from "react";
import {
  Box,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";
import NoData from "components/ui/noData/NoData";
import NoDataFound from "components/ui/noDataFound/NoDataFound";
//

const LeaveReportsTable = ({ data, isLoading }) => {
  const { t } = useTranslation();

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const employeeData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/
  if (isLoading) {
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  if (data?.length === 0) {
    return <NoDataFound />;
  }

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            borderColor="primary"
          />
        </Flex>

        <GButton
          title={t("general.filter")}
          border="1px solid"
          borderColor="border"
          postfixElement={<FiIcons.FiFilter size={16} />}
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          //onClick={() => setShowFilterCategoriesModal(true)}
        />
      </Flex>

      <Table>
        <Thead>
          <Tr>
            <Th>{t("pages.hr.code")}</Th>
            <Th>{t("pages.hr.name")}</Th>
            <Th>{t("pages.hr.leave")}</Th>

            <Th>{t("pages.hr.carryOver")}</Th>
            <Th>{t("pages.hr.annual_entitled")}</Th>
            <Th>{t("pages.hr.opening_balance")}</Th>
            <Th>{t("pages.hr.colosed_balance")}</Th>
            <Th>{t("pages.hr.department")}</Th>
            <Th>{t("pages.hr.remaining")}</Th>
            <Th>{t("pages.hr.consumed")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {employeeData?.map((el, index) => (
            <Tr key={index}>
              <Td> {el?.EmployeeCode} </Td>
              <Td> {el?.FullEnName} </Td>
              <Td> {el?.VacationTypeName} </Td>
              <Td> {el?.CarryOverBalance} </Td>

              <Td> {el?.CurrentEntitled} </Td>
              <Td> {el?.CurrentOpening} </Td>
              <Td> {el?.cruntmonthBalance} </Td>
              <Td> {el?.annualBlance} </Td>
              <Td> {el?.YtDConsumed} </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(data?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(data?.length / recordsPerPage)
          )
        }
      />
    </Box>
  );
};

export default LeaveReportsTable;
