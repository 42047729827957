import React from "react";
import { Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function AttendanceDays({ attendanceList }) {
  console.log(attendanceList);
  const { t } = useTranslation();

  return (
    <>
      <Table>
        <Thead bg={"blue.500"}>
          <Tr>
            <Th color={"white"}>#</Th>
            <Th color={"white"}>{t("pages.hr.Employee_code")}</Th>
            <Th color={"white"}>{t("pages.hr.employee_department")}</Th>
            <Th color={"white"}>{t("pages.hr.sign_in")}</Th>
            <Th color={"white"}>{t("pages.hr.sign_out")}</Th>
          </Tr>
        </Thead>
        {attendanceList?.length > 0 ? (
          <Tbody>
            {attendanceList?.map((item, index) => (
              <Tr key={index}>
                <Td> {index + 1} </Td>
                <Td>{item?.Code}</Td>
                <Td>{item?.Departmen}</Td>
                <Td>{item?.SignIn}</Td>
                <Td>{item?.SignOut}</Td>
              </Tr>
            ))}
          </Tbody>
        ) : (
          ""
        )}
      </Table>

      {attendanceList?.length === 0 && (
        <Text
          fontSize="lg"
          fontWeight={"bold"}
          color="blue.600"
          padding={"20px"}
          textAlign={"center"}
        >
          "No Days Matching Date"
        </Text>
      )}
    </>
  );
}
