import React, { useEffect } from "react";
import { Box, Flex, SimpleGrid, FormControl, FormErrorMessage, FormLabel, Stack, useToast, Input as ChInput, Input, Text,} from "@chakra-ui/react";
import GButton from "components/ui/button/Button";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import { Controller, useForm } from "react-hook-form";

export default function AddEmployeePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit,  control,  getValues,  watch,  setValue,  register,  formState: { errors },  trigger,  setError,  clearErrors,} = useForm();

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  useEffect(() => {
    const minLevel = parseInt(getValues("MinLevel"), 10);
    const maxLevel = parseInt(getValues("MaxLevel"), 10);
    const parLevel = parseInt(getValues("ParLevel"), 10);

    // Check if minLevel is greater than maxLevel
    if (!isNaN(minLevel) && !isNaN(maxLevel) && minLevel >= maxLevel) {
      setError("MinLevel", {
        type: "manual",
        message: t("validation.must_be_less_than_max_level"),
      });
    } else {
      clearErrors("MinLevel");
    }

    // Check if parLevel is within the range of minLevel and maxLevel
    if (!isNaN(parLevel) && (parLevel < minLevel || parLevel > maxLevel)) {
      setError("ParLevel", {
        type: "manual",
        message: t("validation.must_be_within_min_max_range"),
      });
    } else {
      clearErrors("ParLevel");
    }
  }, [getValues("MinLevel"), getValues("MaxLevel"), getValues("ParLevel")]);

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          //   if (values.InventoryCategoryId === undefined) {
          //       toast2({ description: t("validation.choose_first") });
          //   } else {
          //     dispatch(createItem(values))
          //       .unwrap()
          //       .then((_) => {
          //         toast({ description: t("pages.inventory.item_created") });
          //         dispatch(getItems());
          //         onClose();
          //       });
          //   }
        })}
      >
        <Box bg={"white"}  paddingY={5} paddingX={5}>
            <Text color="dark" textTransform="capitalize" fontSize='xl' fontWeight={"bold"} >
                {t("pages.hr.basic_information")}
            </Text>
            <SimpleGrid
              spacing={6}
              columns={{ base: 1, md: 2 }}
              bg="white"
              p={4}
              borderRadius={6}
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            >
              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.Employee_code")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.Employee_code")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.Employee_name")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.Employee_name")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.date}>
                <FormLabel
                  htmlFor="date"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.date_of_birth")}
                </FormLabel>
                <Controller
                  name="date"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type="date"
                      {...field}
                      placeholder={t("pages.hr.date_of_birth")}
                      onChange={(e) => {
                        handleInputChange(e, "date");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.date &&
                    errors.date.message}
                </FormErrorMessage>
              </FormControl>

              <MultiSelect
                title={t("pages.hr.Marital_Status")}
                // data={
                //   inventoryCatData.data &&
                //   inventoryCatData.data.map((el) => ({
                //     ...el,
                //     label: el.InventoryCategoryName,
                //     value: el.InventoryCategoryId,
                //   }))
                // }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("MaritalStatus", e)}
              />

            </SimpleGrid>
        </Box>

        <Box bg={"white"}  paddingY={5} paddingX={5}>
            <Text color="dark" textTransform="capitalize" fontSize='xl' fontWeight={"bold"} >
                {t("pages.hr.National_id")}
            </Text>
            <SimpleGrid
              spacing={6}
              columns={{ base: 1, md: 2 }}
              bg="white"
              p={4}
              borderRadius={6}
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            >
              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.National_id")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.National_id")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.nationality")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.nationality")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.extraction_place")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.extraction_place")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.ExtractionDate}>
                <FormLabel
                  htmlFor="ExtractionDate"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.extraction_date")}
                </FormLabel>
                <Controller
                  name="ExtractionDate"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type="date"
                      {...field}
                      placeholder={t("pages.hr.extraction_date")}
                      onChange={(e) => {
                        handleInputChange(e, "ExtractionDate");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.ExtractionDate &&
                    errors.ExtractionDate.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.EndDate}>
                <FormLabel
                  htmlFor="EndDate"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.end_date")}
                </FormLabel>
                <Controller
                  name="EndDate"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type="date"
                      {...field}
                      placeholder={t("pages.hr.end_date")}
                      onChange={(e) => {
                        handleInputChange(e, "EndDate");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.EndDate &&
                    errors.EndDate.message}
                </FormErrorMessage>
              </FormControl>

            </SimpleGrid>
        </Box>

        <Box bg={"white"}  paddingY={5} paddingX={5}>
            <Text color="dark" textTransform="capitalize" fontSize='xl' fontWeight={"bold"} >
                {t("pages.hr.Job_details")}
            </Text>
            <SimpleGrid
              spacing={6}
              columns={{ base: 1, md: 2 }}
              bg="white"
              p={4}
              borderRadius={6}
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            >
              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.job_title")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.job_title")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.Department")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.Department")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.sub_Department")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.sub_Department")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <MultiSelect
                title={t("pages.hr.Classification")}
                // data={
                //   inventoryCatData.data &&
                //   inventoryCatData.data.map((el) => ({
                //     ...el,
                //     label: el.InventoryCategoryName,
                //     value: el.InventoryCategoryId,
                //   }))
                // }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("Classification", e)}
              />

              <FormControl isInvalid={errors.HireDate}>
                <FormLabel
                  htmlFor="HireDate"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.hire_Date")}
                </FormLabel>
                <Controller
                  name="HireDate"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type="date"
                      {...field}
                      placeholder={t("pages.hr.hire_Date")}
                      onChange={(e) => {
                        handleInputChange(e, "HireDate");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.HireDate &&
                    errors.HireDate.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.EndOfContract}>
                <FormLabel
                  htmlFor="EndOfContract"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.end_of_contract")}
                </FormLabel>
                <Controller
                  name="EndOfContract"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type="date"
                      {...field}
                      placeholder={t("pages.hr.end_of_contract")}
                      onChange={(e) => {
                        handleInputChange(e, "EndOfContract");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.EndOfContract &&
                    errors.EndOfContract.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.full_time")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.full_time")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.report_to")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.report_to")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.position_code")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.position_code")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

            </SimpleGrid>
        </Box>

        <Box bg={"white"}  paddingY={5} paddingX={5}>
            <Text color="dark" textTransform="capitalize" fontSize='xl' fontWeight={"bold"} >
                {t("pages.hr.contact_Information")}
            </Text>
            <SimpleGrid
              spacing={6}
              columns={{ base: 1, md: 2 }}
              bg="white"
              p={4}
              borderRadius={6}
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            >
              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.Email")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.Email")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.Phone")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.Phone")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.Address")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.Address")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

            </SimpleGrid>
        </Box>

        <Box bg={"white"}  paddingY={5} paddingX={5}>
            <Text color="dark" textTransform="capitalize" fontSize='xl' fontWeight={"bold"} >
                {t("pages.hr.Emergency_contact")}
            </Text>
            <SimpleGrid
              spacing={6}
              columns={{ base: 1, md: 2 }}
              bg="white"
              p={4}
              borderRadius={6}
              boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
            >
              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.Name")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.Name")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.Relation")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.Relation")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.InventoryItemNameEng}>
                <FormLabel
                  htmlFor="InventoryItemNameEng"
                  style={{ textTransform: "capitalize" }}
                >
                  {t("pages.hr.Phone")}
                </FormLabel>
                <Controller
                  name="InventoryItemNameEng"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("validation.required"),
                    minLength: {
                      value: 2,
                      message: t("validation.name_long_2"),
                    },
                    maxLength: {
                      value: 25,
                      message: t("validation.name_long_25"),
                    },
                    pattern: {
                      value: /^[\p{L}\s]+$/u,
                      message: t("validation.this_name_invalid"),
                    },
                    validate: {
                      noOnlySpaces: (value) =>
                        !isOnlySpaces(value) || t("validation.no_only_spaces"),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t("pages.hr.Phone")}
                      onChange={(e) => {
                        handleInputChange(e, "InventoryItemNameEng");
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.InventoryItemNameEng &&
                    errors.InventoryItemNameEng.message}
                </FormErrorMessage>
              </FormControl>

            </SimpleGrid>
        </Box>

        <Box bg={"white"}  paddingY={5} paddingX={5}>
            <Flex gap={2} flexWrap="wrap">
            <GButton
                title={t("pages.profile.save_changes")}
                //   onClick={() => setShowCreatCostAdjustmentModal(true)}
                color="light"
                border="1px solid transparent"
                bg="primary"
                hover={{ bg: "none", borderColor: "primary", color: "primary" }}
            />
            </Flex>
        </Box>
      </form>
    </>
  );
};
