import React from 'react';
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import MultiSelect from "components/ui/multiSelect/MultiSelect";

const OverTimePage = () => {
    const { t } = useTranslation();
    const inventoryCatData=[]
  return (
    <div>
    <Box
    bg="light" marginInline={-6} marginTop={-6} padding={6}
    borderTop="1px solid" borderColor="border" marginBottom={6}
>
    <Flex alignItems="center" justifyContent="space-between" gap={4}>
        <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
        >
            {t("pages.hr.overTime")}
        </Text>


        <Flex gap={2}>
        </Flex>
    </Flex>
</Box>


<Box
bg="light" marginInline={-6} marginTop={6} padding={6}
borderTop="1px solid" borderColor="border" marginBottom={6}
>
<Flex alignItems="center" justifyContent="space-between" gap={4}>
    <MultiSelect
        title={t("pages.hr.select_year")}
        data={
            inventoryCatData.data &&
            inventoryCatData.data.map((el) => ({
                ...el,
                label: el.InventoryCategoryName,
                value: el.InventoryCategoryId,
            }))
        }
        isMulti={false}
        isRequired={true}
    //onChange={(e) => setValue("InventoryCategoryId", e)}
    />

    <MultiSelect
        title={t("pages.hr.select_month")}
        data={
            inventoryCatData.data &&
            inventoryCatData.data.map((el) => ({
                ...el,
                label: el.InventoryCategoryName,
                value: el.InventoryCategoryId,
            }))
        }
        isMulti={false}
        isRequired={true}
    //onChange={(e) => setValue("InventoryCategoryId", e)}
    />

    <MultiSelect
        title={t("pages.hr.select_report")}
        data={
            inventoryCatData.data &&
            inventoryCatData.data.map((el) => ({
                ...el,
                label: el.InventoryCategoryName,
                value: el.InventoryCategoryId,
            }))
        }
        isMulti={false}
        isRequired={true}
    //onChange={(e) => setValue("InventoryCategoryId", e)}
    />


    <Flex gap={2}>
    <button  style={{backgroundColor:"#45b6fe",padding:"15px",borderRadius:"15px",color:"white",cursor:"pointer"}}>
        submit
    </button>
    </Flex>
</Flex>
</Box>
    </div>
  );
}

export default OverTimePage;
