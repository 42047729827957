import React from 'react';
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import TodayAttendanceTable from '../components/TodayAttendanceTable';
import { SlCalender } from "react-icons/sl";


const TodayAttendancePage = () => {

    const { t } = useTranslation();


    const getFormattedDate = () => {
      const today = new Date();
      
      const day = today.getDate();
      const month = today.getMonth() + 1; // Months are zero-based
      const year = today.getFullYear();
    
      // Format the date as dd/mm/yyyy
      const formattedDate = `${day}/${month}/${year}`;
    
      return formattedDate;
    };


  return (
    <div>
    <Box
    bg="light" marginInline={-6} marginTop={-6} padding={6}
    borderTop="1px solid" borderColor="border" marginBottom={6}
  >
    <Flex alignItems="center" justifyContent="space-between" gap={4}>
      <Text
        fontSize="1.2rem" color="dark" textTransform="capitalize"
      >
        {t("pages.hr.today_attendance")}
      </Text>


      <Flex gap={2}>        
      </Flex>
    </Flex>
  </Box>

  <div style={{ backgroundColor: "#45b6fe", width: "fit-content", padding: "25px", marginBottom: "25px",borderRadius:"15px",display:"flex",alignItems:"center",justifyContent:"space-between",gap:"25px" }}>
    <SlCalender style={{color:"white"}} />
    <p style={{color:"white"}}>{getFormattedDate()}</p>
  </div>


  <TodayAttendanceTable/>
    </div>
  );
}

export default TodayAttendancePage;
