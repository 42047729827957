import { createSlice } from "@reduxjs/toolkit";
import {
  GetAbsenceDays,
  GetAttendanceDays,
  GetContractList,
  GetEmployeePremissions,
  GetEmployeeVacations,
  editBasicInfo,
  editContactInfo,
  editExtraInfo,
  editJobDetails,
  editNationalityInfo,
  getEmployeeById,
  getEmployeeList,
  getMartialStatus,
  getNationalityDropdown,
  getRecruitmentSituation,
  getReligion,
} from "./../services/employeeServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  employeeList: [],
  employeeDetails: [],
  employeeContractList: [],
  employeeVacationsList: [],
  employeePermessionsList: [],
  // Attendance && Absence List
  attendanceList: [],
  absenceList: [],

  // Martial Status
  MartialStatusDropDown: [],
  ReligionDropDown: [],
  RecruitmentSituationDropDown: [],
  nationalityDropDown: [],
  // Filter data
  filteredData: [],
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  extraReducers: (builder) => {
    /* -------------------------------------------- Get Employee List dropDown -------------------------------------------- */
    builder.addCase(getEmployeeList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getEmployeeList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getEmployeeList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------------------------------- Get Employee Details dropDown -------------------------------------------- */
    builder.addCase(getEmployeeById.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getEmployeeById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeDetails =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getEmployeeById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Family Status DropDown ---------------------*/
    builder.addCase(getMartialStatus.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getMartialStatus.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.MartialStatusDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getMartialStatus.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Religion DropDown ---------------------*/
    builder.addCase(getReligion.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getReligion.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.ReligionDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getReligion.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Recruitment Situation DropDown ---------------------*/
    builder.addCase(getRecruitmentSituation.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getRecruitmentSituation.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.RecruitmentSituationDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getRecruitmentSituation.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Edit Basic Info ---------------------*/
    builder.addCase(editBasicInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editBasicInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editBasicInfo.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------  Get Nationality DropDown  ---------------------*/
    builder.addCase(getNationalityDropdown.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getNationalityDropdown.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.nationalityDropDown =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getNationalityDropdown.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Edit Nationality Info  ---------------------*/
    builder.addCase(editNationalityInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editNationalityInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editNationalityInfo.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Edit Contact Info  ---------------------*/
    builder.addCase(editContactInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editContactInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editContactInfo.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Edit Job Details Info  ---------------------*/
    builder.addCase(editJobDetails.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editJobDetails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editJobDetails.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Edit Extra Info  ---------------------*/
    builder.addCase(editExtraInfo.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(editExtraInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    });
    builder.addCase(editExtraInfo.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Attendance Days  ---------------------*/
    builder.addCase(GetAttendanceDays.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(GetAttendanceDays.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.attendanceList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(GetAttendanceDays.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Absence Days  ---------------------*/
    builder.addCase(GetAbsenceDays.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(GetAbsenceDays.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.absenceList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(GetAbsenceDays.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Contract List  ---------------------*/
    builder.addCase(GetContractList.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(GetContractList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeContractList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(GetContractList.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Vacations List  ---------------------*/
    builder.addCase(GetEmployeeVacations.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(GetEmployeeVacations.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeeVacationsList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(GetEmployeeVacations.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
    /* -------------------   Get Premission Orders List  BY Id  ---------------------*/
    builder.addCase(GetEmployeePremissions.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(GetEmployeePremissions.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.employeePermessionsList =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(GetEmployeePremissions.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default employeeSlice.reducer;
