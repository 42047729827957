import React, { useState } from "react";
import { Box, Flex, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
import AbsenceDays from "../tables/AbsenceDays";
import AttendanceDays from "../tables/AttendanceDays";
import AttendanceModal from "../tables/tableModal/AttendanceModal";
import AbsenceModal from "../tables/tableModal/AbsenceModal";

import { FaUserXmark } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa6";

const AttendanceCompo = ({ details, attendanceList, absenceList }) => {
  const { t } = useTranslation();
  const [showTable, setShowTable] = useState("attendance");
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [showAbsenceModal, setShowAbsenceModal] = useState(false);
  // console.log("attendanceList", attendanceList);
  // console.log("absenceList", absenceList);

  return (
    <Box
      bg="light"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Box>
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          borderBottom={"2px"}
          borderColor={"gray.300"}
          padding={3}
          marginBottom={"10px"}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <GButton
              title={t("pages.hr.attendance_days")}
              borderRadius={0}
              color={showTable === "attendance" ? "blue.500" : "none"}
              borderBottom={showTable === "attendance" ? "2px solid" : "none"}
              borderColor={showTable === "attendance" ? "blue.500" : "none"}
              onClick={() => setShowTable("attendance")}
            />
            <GButton
              title={t("pages.hr.absence_days")}
              borderRadius={0}
              color={showTable === "absence" ? "blue.500" : "none"}
              borderBottom={showTable === "absence" ? "2px solid" : "none"}
              borderColor={showTable === "absence" ? "blue.500" : "none"}
              onClick={() => setShowTable("absence")}
            />
          </Flex>
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <Box
              cursor={"pointer"}
              onClick={() => setShowAttendanceModal(true)}
            >
              <Icon
                as={FaUserCheck}
                w={"40px"}
                h={"40px"}
                color="blue.500"
                border={"2px solid"}
                padding={"5px"}
                borderRadius={8}
              />
            </Box>
            <Box cursor={"pointer"} onClick={() => setShowAbsenceModal(true)}>
              <Icon
                as={FaUserXmark}
                w={"40px"}
                h={"40px"}
                color="red.500"
                border={"2px solid"}
                padding={"5px"}
                borderRadius={8}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>

      {showTable === "attendance" ? (
        <AttendanceDays attendanceList={attendanceList} />
      ) : (
        <AbsenceDays absenceList={absenceList} />
      )}

      {showAttendanceModal && (
        <AttendanceModal
          onClose={() => setShowAttendanceModal(false)}
          details={details}
        />
      )}
      {showAbsenceModal && (
        <AbsenceModal
          onClose={() => setShowAbsenceModal(false)}
          details={details}
        />
      )}
    </Box>
  );
};

export default AttendanceCompo;
