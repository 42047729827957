import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  editJobDetails,
  getEmployeeById,
} from "../../services/employeeServices";

export default function EditJobDetails({
  id,
  onClose,
  PositionDropdown,
  details,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, setValue } = useForm();

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.edit_job_details")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.EmployeeId = id;
                values.ToJob = values.ToJob?.PositionId || details?.PositionId;
                console.log(values);

                if (values?.ToJob === undefined) {
                  toast2({
                    description: t("pages.hr.hr_validation.select_item"),
                  });
                } else {
                  dispatch(editJobDetails(values))
                    .unwrap()
                    .then((_) => {
                      toast({
                        description: t(
                          "pages.hr.job_details_info_updated_successfuly"
                        ),
                      });
                      dispatch(getEmployeeById(id));
                      onClose();
                    });
                }
              })}
            >
              <Stack spacing={6}>
                <Box>
                  <Text fontWeight={"semibold"} color="blue.500">
                    {t("pages.hr.Employee_name")}
                  </Text>
                  <Input
                    size="md"
                    type="text"
                    marginTop={"2"}
                    value={details?.FullEnName}
                  />
                </Box>

                <Box>
                  <Text fontWeight={"semibold"} color="blue.500">
                    {t("pages.hr.Employee_code")}
                  </Text>
                  <Input
                    size="md"
                    type="text"
                    marginTop={"2"}
                    value={details?.EmployeeCode}
                  />
                </Box>

                <Box>
                  <Text fontWeight={"semibold"} color="blue.500">
                    {t("pages.hr.from")}
                  </Text>
                  <Input
                    size="md"
                    type="text"
                    marginTop={"2"}
                    value={
                      PositionDropdown[details?.PositionId - 1]?.Reportingto
                    }
                  />
                </Box>

                <Box>
                  <MultiSelect
                    title={t("pages.hr.to")}
                    isMulti={false}
                    defaultValue={{
                      label:
                        PositionDropdown[details?.PositionId - 1]?.Reportingto,
                    }}
                    data={
                      PositionDropdown &&
                      PositionDropdown?.map((el) => ({
                        ...el,
                        label: el.Reportingto,
                        value: el.PositionId,
                      }))
                    }
                    onChange={(e) => setValue("ToJob", e)}
                  />
                </Box>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
