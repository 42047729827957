import axios from "axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");

/* -------------------  Get Classification ---------------------*/
export const getEmployeeList = createAsyncThunk(
  "employee/getEmployeeList",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Employee/EmployeeList`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Family Status DropDown ---------------------*/
export const getMartialStatus = createAsyncThunk(
  "employee/getMartialStatus",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/LookupFamilyStatus`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Religion DropDown ---------------------*/
export const getReligion = createAsyncThunk(
  "employee/getReligion",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/LookupReligion`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Recruitment Situation DropDown ---------------------*/
export const getRecruitmentSituation = createAsyncThunk(
  "employee/getRecruitmentSituation",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/LookupRecruitmentSituation`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Classification ---------------------*/
export const getEmployeeById = createAsyncThunk(
  "employee/getEmployeeById",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Employee/GetEmployeeById?employeeid={"EmployeeId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Edit Basic Info ---------------------*/
export const editBasicInfo = createAsyncThunk(
  "employee/editBasicInfo",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/EditBasicInfo?employeeid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------  Get Nationality DropDown  ---------------------*/
export const getNationalityDropdown = createAsyncThunk(
  "employee/getNationalityDropdown",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/LookupNationality`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------   Edit Nationality Info  ---------------------*/
export const editNationalityInfo = createAsyncThunk(
  "employee/editNationalityInfo",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/EditIdentityInfo?employeeid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------   Edit Job Details Info  ---------------------*/
export const editJobDetails = createAsyncThunk(
  "employee/editJobDetails",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/EditJobDetails?employeeid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------   Edit Contact Info  ---------------------*/
export const editContactInfo = createAsyncThunk(
  "employee/editContactInfo",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/EditContactInfo?employeeid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------   Edit Extra Info  ---------------------*/
export const editExtraInfo = createAsyncThunk(
  "employee/editExtraInfo",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/HR/EditExtraInfo?employeeid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------   Get Attendance Days  ---------------------*/
export const GetAttendanceDays = createAsyncThunk(
  "employee/GetAttendanceDays",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetAttendaceForEmployee?employeeid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------   Get Absence Days  ---------------------*/
export const GetAbsenceDays = createAsyncThunk(
  "employee/GetAbsenceDays",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetAbsentForEmployee?employeeid=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------   Get Contract List  ---------------------*/
export const GetContractList = createAsyncThunk(
  "employee/GetContractList",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetContractByEmployeeId?employeeid={"EmployeeId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------   Get Vacations List  ---------------------*/
export const GetEmployeeVacations = createAsyncThunk(
  "employee/GetEmployeeVacations",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetVactionByEmployeeId?employeeid={"EmployeeId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------   Get Premission Orders List  BY Id  ---------------------*/
export const GetEmployeePremissions = createAsyncThunk(
  "employee/GetEmployeePremissions",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetPermissionByEmployeeId?employeeid={"EmployeeId":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
