import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  Text,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  editBasicInfo,
  getEmployeeById,
} from "../../services/employeeServices";

export default function EditBasicModal({
  id,
  onClose,
  classifications,
  MartialStatusDropDown,
  ReligionDropDown,
  RecruitmentSituationDropDown,
  details,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  const dateFromStr = details?.BirthDate;
  const [day, month, year] = dateFromStr.split("-");

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.edit_basic_info")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.EmployeeId = id;

                values.EmpClassificationId =
                  values.EmpClassificationId?.EmployeeClassificationId ||
                  details.EmployeeClassificationId;

                values.EmpStatusId =
                  values.EmpStatusId?.FamilyStatusId || details.FamilyStatusId;

                values.ReligionId =
                  values.ReligionId?.ReligionId || details?.ReligionId;

                values.RecruitmentSituationId =
                  values.RecruitmentSituationId?.RecruitmentSituationId ||
                  details?.RecruitmentSituationId;

                values.BirthDate = values?.BirthDate || details?.BirthDate;

                dispatch(editBasicInfo(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t("pages.hr.basic_info_updated"),
                    });
                    dispatch(getEmployeeById(id));
                    onClose();
                  });
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.FullEnName}>
                  <FormLabel
                    htmlFor="FullEnName"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.Employee_name")}
                  </FormLabel>
                  <Controller
                    name="FullEnName"
                    control={control}
                    defaultValue={details.FullEnName}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 2,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 25,
                        message: t("validation.name_long_25"),
                      },
                      pattern: {
                        value: /^[\p{L}\s]+$/u,
                        message: t("validation.this_name_invalid"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.Employee_name")}
                        onChange={(e) => {
                          handleInputChange(e, "FullEnName");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.FullEnName && errors.FullEnName.message}
                  </FormErrorMessage>
                </FormControl>

                <Box>
                  <MultiSelect
                    title={t("pages.hr.Classification")}
                    isMulti={false}
                    defaultValue={{
                      label:
                        classifications[details?.EmployeeClassificationId - 1]
                          ?.EmployeeClassificationNameEn,
                    }}
                    data={
                      classifications &&
                      classifications?.map((el) => ({
                        ...el,
                        label: el.EmployeeClassificationNameEn,
                        value: el.EmployeeClassificationId,
                      }))
                    }
                    onChange={(e) => setValue("EmpClassificationId", e)}
                  />
                </Box>

                <Box>
                  <MultiSelect
                    title={t("pages.hr.marital_status")}
                    isMulti={false}
                    defaultValue={{
                      label:
                        MartialStatusDropDown[details?.FamilyStatusId - 1]
                          ?.FamilyStatusNameEn,
                    }}
                    data={
                      MartialStatusDropDown &&
                      MartialStatusDropDown?.map((el) => ({
                        ...el,
                        label: el.FamilyStatusNameEn,
                        value: el.FamilyStatusId,
                      }))
                    }
                    onChange={(e) => setValue("EmpStatusId", e)}
                  />
                </Box>

                <Box>
                  <MultiSelect
                    title={t("pages.hr.religion")}
                    isMulti={false}
                    defaultValue={{
                      label:
                        ReligionDropDown[details?.ReligionId - 1]
                          ?.ReligionNameEn,
                    }}
                    data={
                      ReligionDropDown &&
                      ReligionDropDown?.map((el) => ({
                        ...el,
                        label: el.ReligionNameEn,
                        value: el.ReligionId,
                      }))
                    }
                    onChange={(e) => setValue("ReligionId", e)}
                  />
                </Box>

                <Box>
                  <MultiSelect
                    title={t("pages.hr.recruitment_situation")}
                    isMulti={false}
                    defaultValue={{
                      label:
                        RecruitmentSituationDropDown[
                          details?.RecruitmentSituationId - 1
                        ]?.RecruitmentSituationNameEn,
                    }}
                    data={
                      RecruitmentSituationDropDown &&
                      RecruitmentSituationDropDown?.map((el) => ({
                        ...el,
                        label: el.RecruitmentSituationNameEn,
                        value: el.RecruitmentSituationId,
                      }))
                    }
                    onChange={(e) => setValue("RecruitmentSituationId", e)}
                  />
                </Box>

                <Box>
                  <Text>{t("pages.hr.birth_date")}</Text>
                  <Input
                    size="md"
                    type="date"
                    marginTop={"2"}
                    onChange={(e) => setValue("BirthDate", e.target.value)}
                    defaultValue={`${year}-${month}-${day}`}
                  />
                </Box>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
