import axios from "axios";
const { createAsyncThunk } = require("@reduxjs/toolkit");

// /* -------------------------------------------------  get Time Leave List ----------------------------------------------------------*/
export const getLookUpTimeLeave = createAsyncThunk(
  "attendanceSettingSlice/getLookUpTimeLeave",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/LookupTimeAttendLeave`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------------------------------------get Year LookUp----------------------------------------------------------*/
export const getYearLookUp = createAsyncThunk(
  "attendanceSettingSlice/getYearLookUp",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/LookupSchoolYear`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------------------------------------  Add New Time ----------------------------------------------------------*/
export const addNewTimeLeave = createAsyncThunk(
  "attendanceSettingSlice/addNewTimeLeave",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Time_AttendLeave/AddTime_AttendLeave?time_attendleave=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* ------------------------------------------------- get TimeLevel Details By Id----------------------------------------------------------*/
export const getTimeLevelDetailsById = createAsyncThunk(
  "attendanceSettingSlice/getTimeLevelDetailsById",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetTimeDetailsByTimeId?timeid={"Time_ID":${args}}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

// /* -------------------------------------------------add New Time Shift----------------------------------------------------------*/
export const addNewTimeShift = createAsyncThunk(
  "attendanceSettingSlice/addNewTimeShift",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Time_Details/AddTime_Details?time_details=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------------------------------------Edit Time Shift----------------------------------------------------------*/
export const editTimeShift = createAsyncThunk(
  "attendanceSettingSlice/editTimeShift",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Time_Details/EditTime_Details?timedetails_id=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* ------------------------------------------------- Group Shift----------------------------------------------------------*/
/* -------------------------------------------------Get Days Shift ----------------------------------------------------------*/
export const getGroupShiftDays = createAsyncThunk(
  "attendanceSettingSlice/getGroupShiftDays",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/LookupAttendLeaveGroupDetails`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
/* -------------------------------------------------Get Group Shift List----------------------------------------------------------*/
export const getGroupShift = createAsyncThunk(
  "attendanceSettingSlice/getGroupShift",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/GetGroupShiftList`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------------------------------------add New Group Shift----------------------------------------------------------*/
export const addNewGroupShift = createAsyncThunk(
  "attendanceSettingSlice/addNewGroupShift",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios.get(
      `https://rostoapi.smartgate-egypt.com/Hr/Attend_Leave_Group/AddAttend_Leave_Group?attend_leave_group=${JSON.stringify(
        args
      )}`
    );
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
// /* -------------------------------------------------Get Group Shift By Id----------------------------------------------------------*/
export const getGroupShiftDetails = createAsyncThunk(
  "attendanceSettingSlice/getGroupShiftDetails",
  async (args, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    const { data } = await axios
      .get
      // `https://rostoapi.smartgate-egypt.com/Hr/Attend_Leave_Group/AddAttend_Leave_Group?attend_leave_group=${JSON.stringify(
      //   args
      // )}`
      ();
    try {
      return fulfillWithValue(data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
