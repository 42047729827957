import React, { useState } from "react";
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  CloseButton,
  Spinner,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import CancelVacationModal from "./modal/CancelVacationModal";
import EditVacationModal from "./modal/EditVacationModal";
import ViewVacationModal from "./modal/ViewVacationModal";

export default function VacationListTabel({
  data,
  isLoading,
  classifications,
}) {
  // console.log(data);
  const { t } = useTranslation();
  const [showCancelModal, setShowCancelModal] = useState(null);
  const [showEditModal, setShowEditModal] = useState(null);
  const [showViewModal, setShowViewModal] = useState(null);

  if (isLoading) {
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    );
  }
  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            borderColor="primary"
          />
        </Flex>

        <GButton
          title={t("general.filter")}
          border="1px solid"
          borderColor="border"
          postfixElement={<FiIcons.FiFilter size={16} />}
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
        />
      </Flex>

      <Table>
        <Thead>
          <Tr>
            <Th>{t("pages.hr.id")}</Th>
            <Th>{t("pages.hr.Official_leave_causes")}</Th>
            <Th>{t("pages.hr.from_date")}</Th>
            <Th>{t("pages.hr.to_date")}</Th>
            <Th>{t("pages.hr.view")}</Th>
            {/* <Th>{t("pages.hr.edit")}</Th> */}
            <Th>{t("pages.hr.cancel")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((el, index) => {
            return (
              <Tr>
                <Td>{index + 1}</Td>
                <Td>{el.OfficialVacationCause}</Td>
                <Td>{el.FromDate}</Td>
                <Td>{el.ToDate}</Td>

                <Td>
                  <Button
                    colorScheme="green"
                    size="sm"
                    onClick={() => setShowViewModal(el)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("pages.hr.view")} </span>
                    </Flex>
                  </Button>
                </Td>

                {/* <Td>
                  <Button
                    colorScheme="telegram"
                    size="sm"
                    onClick={() => setShowEditModal(el)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("pages.hr.edit")} </span>
                    </Flex>
                  </Button>
                </Td> */}
                <Td>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => setShowCancelModal(el.OfficialVacationId)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("pages.hr.cancel")} </span>
                    </Flex>
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {/* ------------------------  Show Cancel Modals ------------------------*/}
      {showCancelModal && (
        <CancelVacationModal
          onClose={() => setShowCancelModal(null)}
          id={showCancelModal}
        />
      )}
      {/* ------------------------  Show Edit Modals ------------------------*/}
      {showEditModal && (
        <EditVacationModal
          onClose={() => setShowEditModal(null)}
          item={showEditModal}
          classifications={classifications}
        />
      )}
      {/* ------------------------  Show View Modals ------------------------*/}
      {showViewModal && (
        <ViewVacationModal
          onClose={() => setShowViewModal(null)}
          item={showViewModal}
          classifications={classifications}
        />
      )}
    </Box>
  );
}
