import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, Flex, Input, Stack, Text } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { getPositionDetailsById } from "../../services/positionsServices";

export default function ViewModal({
  onClose,
  id,
  classifications,
  departments,
  jobTitles,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { positionDetails } = useSelector((state) => state.positionsSlice);
  useEffect(() => {
    dispatch(getPositionDetailsById(id));
  }, [dispatch, id]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.position.view_position_details")}
        modalContent={
          <Box p={4}>
            <form>
              <Stack spacing={6}>
                <Box>
                  <Text fontWeight={"semibold"} color="blue.500">
                    {t("pages.hr.position.position_code")}
                  </Text>
                  <Input
                    size="md"
                    type="text"
                    marginTop={"2"}
                    value={positionDetails[0]?.PositionCode}
                  />
                </Box>
                <Box>
                  <Text fontWeight={"semibold"} color="blue.500">
                    {t("pages.hr.position.position_department")}
                  </Text>
                  <Input
                    size="md"
                    type="text"
                    marginTop={"2"}
                    value={
                      departments[positionDetails[0]?.DepartmentId]
                        ?.DepartmentName_EN
                    }
                  />
                </Box>
                <Box>
                  <Text fontWeight={"semibold"} color="blue.500">
                    {t("pages.hr.position.reporting_to")}
                  </Text>
                  <Input
                    size="md"
                    type="text"
                    marginTop={"2"}
                    value={`${positionDetails[0]?.ReportingTo}`}
                  />
                </Box>
                <Box>
                  <Text fontWeight={"semibold"} color="blue.500">
                    {t("pages.hr.position.Positions_name")}
                  </Text>
                  <Input
                    size="md"
                    type="text"
                    marginTop={"2"}
                    value={positionDetails[0]?.PositionName}
                  />
                </Box>
                <Box>
                  <Text fontWeight={"semibold"} color="blue.500">
                    {t("pages.hr.position.classification")}
                  </Text>
                  <Input
                    size="md"
                    type="text"
                    marginTop={"2"}
                    value={
                      classifications[
                        positionDetails[0]?.EmployeeClassificationId
                      ]?.EmployeeClassificationNameEn
                    }
                  />
                </Box>

                <Box>
                  <Text fontWeight={"semibold"} color="blue.500">
                    {t("pages.hr.position.job_title")}
                  </Text>
                  <Input
                    size="md"
                    type="text"
                    marginTop={"2"}
                    value={
                      jobTitles[positionDetails[0]?.JobTitleId]?.JobTitleEn
                    }
                  />
                </Box>
                <Checkbox colorScheme="green" isChecked={false}>
                  {t("pages.hr.IsAcademicLeave")}
                </Checkbox>

                <Checkbox colorScheme="green" isChecked={false}>
                  {t("pages.hr.position.isFullTime")}
                </Checkbox>
                <Checkbox
                  colorScheme="green"
                  isChecked={positionDetails[0]?.IsActive}
                >
                  {t("pages.hr.is_active")}
                </Checkbox>

                <Checkbox
                  colorScheme="green"
                  isChecked={positionDetails[0]?.IsSMG}
                >
                  {t("pages.hr.position.is_smg")}
                </Checkbox>

                <Flex gap={4} justifyContent={"flex-end"}>
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
