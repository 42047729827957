import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { BsDatabaseFillX } from "react-icons/bs";

const NoDataFound = () => {
  const { t, i18n } = useTranslation();

  return (
    <Flex
      p={4}
      bg="white"
      alignItems="center"
      justifyContent="center"
      flexDir={"column"}
      color={"gray.500"}
      textTransform="capitalize"
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      borderRadius={6}
    >
      <BsDatabaseFillX size={50} />
      <Text fontWeight={"bold"} marginY={3} fontSize={"xl"}>
        {t("pages.branches.no_data")}
      </Text>
    </Flex>
  );
};

export default NoDataFound;
