import React from 'react';
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';
import PayRollListTable from '../components/PayRollListTable';

const PayrollPage = () => {
    const { t } = useTranslation();

  return (
    <div>
    <Box
    bg="light" marginInline={-6} marginTop={-6} padding={6}
    borderTop="1px solid" borderColor="border" marginBottom={6}
>
    <Flex alignItems="center" justifyContent="space-between" gap={4}>
        <Text
            fontSize="1.2rem" color="dark" textTransform="capitalize"
        >
            {t("pages.hr.Payroll")}
        </Text>


        <Flex gap={2}>

        
        <Button
        type="button" bg="primary" color="light"
        textTransform="capitalize" fontSize="1rem"
        //onClick={() => setCreateItemModalstate(true)}
      >
        <Flex alignItems="center" gap={1} >
          {t("pages.hr.add")}
        </Flex>
      </Button>

        </Flex>
    </Flex>
</Box>

<PayRollListTable/>
    </div>
  );
}

export default PayrollPage;
