import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import LeaveReportsTable from "../components/LeaveReportsTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getHrDepartmentDropdown,
  getHrLeaveReports,
  getHrVacationTypeDropdown,
} from "../services/leaveReportsServices";
import { useForm } from "react-hook-form";

const LeaveReportsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, setValue } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  const data = useSelector((state) => state.leaveReports);
  console.log(data);

  // departmentDropDown
  useEffect(() => {
    dispatch(getHrDepartmentDropdown());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getHrVacationTypeDropdown());
  }, [dispatch]);

  return (
    <div>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.leave")}
          </Text>

          <Flex gap={2}></Flex>
        </Flex>
      </Box>

      <Box
        bg="light"
        marginInline={0}
        marginTop={6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={3}
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <form
          onSubmit={handleSubmit((values) => {
            values.EmployeeId = null;

            console.log(values);
            if (
              values?.DepartmentId === undefined ||
              values?.VacationType === undefined
            ) {
              toast2({ description: t("validation.choose_first") });
            } else {
              values.DepartmentId = values.DepartmentId?.DepartmentId;
              values.VacationType = values.VacationType?.VacationTypeId;
              console.log(values);
              dispatch(getHrLeaveReports(values));
            }
          })}
        >
          <Stack spacing={8}>
            <Box>
              <MultiSelect
                title={t("pages.hr.select_department")}
                data={
                  data?.departmentDropDown &&
                  data?.departmentDropDown.map((el) => ({
                    ...el,
                    label: el.DepartmentName_EN,
                    value: el.DepartmentId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("DepartmentId", e)}
              />
            </Box>
            {/* <Box>
              <MultiSelect
                title={t("pages.hr.select_employee")}
                data={
                  inventoryCatData.data &&
                  inventoryCatData.data.map((el) => ({
                    ...el,
                    label: el.InventoryCategoryName,
                    value: el.InventoryCategoryId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("EmployeeId", e)}
              />
            </Box> */}
            <Box>
              <MultiSelect
                title={t("pages.hr.select_leaves")}
                data={
                  data?.vacationTypeDropDown &&
                  data.vacationTypeDropDown?.map((el) => ({
                    ...el,
                    label: el.VacationTypeNameEn,
                    value: el.VacationTypeId,
                  }))
                }
                isMulti={false}
                isRequired={true}
                onChange={(e) => setValue("VacationType", e)}
              />
            </Box>

            <Flex gap={2}>
              <Button
                type="submit"
                bg="primary"
                color="light"
                textTransform="capitalize"
                fontSize="1rem"
                width={"120px"}
              >
                <Flex alignItems="center" gap={1}>
                  {t("general.submit")}
                </Flex>
              </Button>
            </Flex>
          </Stack>
        </form>
      </Box>

      <LeaveReportsTable
        isLoading={data?.isLoading}
        data={data?.leaveReportData}
      />
    </div>
  );
};

export default LeaveReportsPage;
