import "../../sidebar/sidebar.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  Link as ChakraLink,
  useTheme,
  Image,
  useMediaQuery
} from "@chakra-ui/react";

import { toggleSidebar } from "../../../store/sidebarSlice";
import logo from "../../../assets/images/logo.png"
import Hrlinks from "./HRLinks.js";
import routes from "../../../navigations/routes";

const HrSideBar = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sidebar = useSelector(state => state.sidebar);
    const theme = useTheme();
    const [isLargeThan992] = useMediaQuery('(min-width: 992px)');
  
    const getCurrentDir = { [theme.start]: { base: sidebar.isActive ? 0 : "-260px", lg: 0 } }
  
    const handleAutoCloseSidebar = () => {
      if (isLargeThan992 === false) {
        dispatch(toggleSidebar())
      }
    }
  
    const IconText = (link) => (
      <>
        <Flex
          flex="1" alignItems="center"
          justifyContent={{ base: "start", lg: sidebar.isActive ? "center" : "start" }}
        >
          {link.icon}
          <Text
            as="p" fontSize="1rem" textTransform="capitalize" paddingStart={4}
            display={{ base: "block", lg: sidebar.isActive ? "none" : "block" }}
            fontWeight="500"
          >
            {t(`${link.text}`)}
          </Text>
        </Flex>
      </>
    );
  
    return (
      <>
        <Box
          position="fixed" top="0" left="0" right="0" bottom="0" bg="rgba(0, 0, 0, .3)" zIndex={98}
          display={{ base: sidebar.isActive ? "block" : "none", lg: "none" }}
          onClick={() => dispatch(toggleSidebar())} cursor="pointer" role="button"
        />
  
        <Box
          width={{ base: "260px", lg: sidebar.isActive ? "80px" : "260px" }}
          height="100vh" transition=".3s"
          overflowY="auto" bg="light" position="fixed"
          top="0" borderEnd="1px solid" borderColor="border"
          {...getCurrentDir} zIndex={99}
        >
          <ChakraLink
            as={Link} to={routes.dashboard_general} display="block" p={4}
            height="70px" borderBottom="1px solid" borderColor="border"
          >
            <Image
              src={logo} alt="brand"
              maxW="100%" height="100%" objectFit="contain"
            />
          </ChakraLink>
  
          {/* <Text
            as="h5" marginTop={6} marginBottom={4} textTransform="uppercase"
            fontSize=".8rem" fontWeight="600" paddingInline={4}
          >
            {t("layout.sidebar.dashboard")}
          </Text> */}
  
          <Accordion allowToggle border="transparent">
            {Hrlinks.map((link, idx) => (
              link.subLinks === null ? (
                <Flex
                  key={idx} end
                  as={NavLink} to={link.path} className="sidebar-link"
                  color="text" _hover={{ bg: "none" }} borderBottom="1px solid" borderColor="border"
                  paddingInline={4} paddingBlock={3}
                  onClick={handleAutoCloseSidebar}
                >
                  {IconText(link)}
                </Flex>
              ) : (
                <AccordionItem key={idx}>
                  <AccordionButton
                    color="text" _hover={{ bg: "none" }} borderBottom="1px solid" borderColor="border"
                    paddingInline={4} borderRadius={0} margin={0} paddingBlock={3}
                  >
                    {IconText(link)}
                  </AccordionButton>
                  <AccordionPanel pb={4} paddingBottom={2} paddingTop={1} paddingStart={8}>
                    <UnorderedList
                      color="text" fontSize=".9rem" listStyleType="none"
                      display="flex" flexDirection="column" gap={1}
                    >
                      {link.subLinks?.map((subLink, j) => (
                        <ListItem key={j}>
                          <ChakraLink
                            as={NavLink} to={subLink.path} className="sidebar-link"
                            textTransform="capitalize" _hover={{ color: "primary" }}
                            onClick={handleAutoCloseSidebar}
                          >
                            {t(`${subLink.text}`)}
                          </ChakraLink>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </AccordionPanel>
                </AccordionItem>
              )
            ))}
          </Accordion>
        </Box>
      </>
    )
}

export default HrSideBar;
