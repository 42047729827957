import React, { useState } from "react";
import { Box, Text, Flex, SimpleGrid, Button } from "@chakra-ui/react";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import EditJobDetails from "../modal/EditJobDetails";
import GButton from "components/ui/button/Button";

const JobDetailsCompo = ({ details, PositionDropdown }) => {
  const { t } = useTranslation();
  const [showEditJobDetailsModal, setShowEditJobDetailsModal] = useState();

  return (
    <Box
      marginBottom={10}
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      border="1px"
      borderColor={"gray.400"}
      width="100%"
      padding="10px"
    >
      <Box padding={3}>
        <Box>
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"2px"}
            borderColor={"gray.300"}
            padding={3}
          >
            <Flex gap={2} alignItems={"center"}>
              <HiOutlineClipboardDocumentList style={{ fontSize: "22px" }} />
              <Text fontSize="xl" fontWeight={"bold"} color="gray.600">
                {t("pages.hr.Job_details")}
              </Text>
            </Flex>
            <Box onClick={() => setShowEditJobDetailsModal(true)}>
              <GButton
                title={t("general.edit")}
                color="black"
                border="1px solid gray"
              />
            </Box>
          </Flex>
        </Box>
        <Box padding={3}>
          <SimpleGrid columns={2} spacing={5}>
            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.job_title")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.JobTitleEn}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.position_code")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.PositionCode}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.hire_Date")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.HireDate}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.Department")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.Department}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.sub_Department")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.ParentDepartment}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.report_to")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {/* {details?.Department} */}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.full_time")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.FullTime ? "Yes" : "No"}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.Classification")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {details?.EmployeeClassificationNameEn}
              </Text>
            </Box>

            <Box
              border={"1px"}
              borderColor={"gray.300"}
              padding={3}
              bg={"gray.50"}
              borderRadius={3}
              textColor="gray.600"
              fontWeight={"semibold"}
              display={"flex"}
            >
              {t("pages.hr.end_of_contract")} :
              <Text fontWeight={"bold"} color="blue.600" mx={"5px"}>
                {/* {details?.EmployeeClassificationNameEn} */}
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
      {showEditJobDetailsModal && (
        <EditJobDetails
          details={details}
          PositionDropdown={PositionDropdown}
          onClose={() => setShowEditJobDetailsModal(false)}
          id={details?.EmployeeId}
        />
      )}
    </Box>
  );
};

export default JobDetailsCompo;
