import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Input,
  Stack,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";

import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import {
  getEmployeeById,
  editContactInfo,
} from "../../services/employeeServices";

export default function EditContactModal({ id, onClose, details }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.edit_contact_info")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.EmployeeId = id;
                dispatch(editContactInfo(values))
                  .unwrap()
                  .then((_) => {
                    toast({
                      description: t(
                        "pages.hr.contact_info_updated_successfuly"
                      ),
                    });
                    dispatch(getEmployeeById(id));
                    onClose();
                  });
              })}
            >
              <Stack spacing={6}>
                <FormControl isInvalid={errors.Mobile}>
                  <FormLabel
                    htmlFor="Mobile"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.Mobile")}
                  </FormLabel>
                  <Controller
                    name="Mobile"
                    control={control}
                    defaultValue={details?.Mobile}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 10,
                        message: t("pages.hr.hr_validation.number_short_10"),
                      },
                      maxLength: {
                        value: 15,
                        message: t("pages.hr.hr_validation.number_long"),
                      },
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.Mobile")}
                        onChange={(e) => {
                          handleInputChange(e, "Mobile");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Mobile && errors.Mobile.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.Telephone}>
                  <FormLabel
                    htmlFor="Telephone"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.Phone")}
                  </FormLabel>
                  <Controller
                    name="Telephone"
                    control={control}
                    defaultValue={details?.phone}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 10,
                        message: t("pages.hr.hr_validation.number_short_10"),
                      },
                      maxLength: {
                        value: 15,
                        message: t("pages.hr.hr_validation.number_long"),
                      },
                      validate: (value) => {
                        const intValue = parseInt(value, 10);
                        return (
                          intValue >= 0 || t("validation.must_be_non_negative")
                        );
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.Phone")}
                        onChange={(e) => {
                          handleInputChange(e, "Telephone");
                        }}
                        type="number"
                        min="0"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.Telephone && errors.Telephone.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.Email}>
                  <FormLabel
                    htmlFor="Email"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.Email")}
                  </FormLabel>
                  <Controller
                    name="Email"
                    control={control}
                    defaultValue={details?.Email}
                    rules={{
                      required: t("validation.required"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t("validation.invalid_email"),
                      },
                      validate: {
                        noOnlySpaces: (value) =>
                          !isOnlySpaces(value) ||
                          t("validation.no_only_spaces"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.Email")}
                        onChange={(e) => {
                          handleInputChange(e, "Email");
                        }}
                        type="email"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {" "}
                    {errors.Email && errors.Email.message}{" "}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.Address}>
                  <FormLabel
                    htmlFor="Address"
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("pages.hr.Address")}
                  </FormLabel>
                  <Controller
                    name="Address"
                    control={control}
                    defaultValue={details?.Address}
                    rules={{
                      required: t("validation.required"),
                      minLength: {
                        value: 10,
                        message: t("validation.name_long_2"),
                      },
                      maxLength: {
                        value: 50,
                        message: t("validation.name_long_25"),
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder={t("pages.hr.Address")}
                        onChange={(e) => {
                          handleInputChange(e, "Address");
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {" "}
                    {errors.Address && errors.Address.message}{" "}
                  </FormErrorMessage>
                </FormControl>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
