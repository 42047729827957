import React, { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";

import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import Pagination from "components/ui/pagination/Pagination";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function GroupShiftTable() {
  const { t } = useTranslation();
  const { GroupShiftList } = useSelector((state) => state.attendanceSetting);
  // console.log(GroupShiftList);
  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (GroupShiftList, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(
      currentPage * recordsPerPage,
      GroupShiftList?.length
    );
    return GroupShiftList?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const GroupShiftListData = calculateRecords(
    GroupShiftList,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return (
    <>
      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            {/* <GButton
              title={t("general.all")}
              bg="none"
              isDisabled={false}
              borderRadius={0}
              borderColor="primary"
            /> */}
          </Flex>

          <GButton
            title={t("general.filter")}
            border="1px solid"
            borderColor="border"
            postfixElement={<FiIcons.FiFilter size={16} />}
            hover={{ bg: "primary", borderColor: "primary", color: "light" }}
            //onClick={() => setShowFilterCategoriesModal(true)}
          />
        </Flex>

        <Table>
          <Thead>
            <Tr>
              <Th>{t("pages.hr.attendance.group_shift_name")}</Th>
              <Th>{t("pages.hr.attendance.is_weekend_cycle")}</Th>
              <Th>{t("pages.hr.attendance.weekend_cycle_days")}</Th>
              <Th>{t("pages.hr.attendance.flexiable_hours")}</Th>
              <Th>{t("pages.hr.attendance.hour_per_day")}</Th>
              <Th>{t("pages.hr.attendance.is_active")}</Th>
              <Th>{t("pages.hr.attendance.view")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {GroupShiftListData?.map((item, index) => (
              <Tr key={index} cursor={"pointer"}>
                <Td> {item?.Attend_Leave_GroupName} </Td>

                <Td>
                  {item?.IsWeekendCycle ? (
                    <Flex justifyContent={"center"} textColor={"green.500"}>
                      <FaCheck size={25} />
                    </Flex>
                  ) : (
                    <Flex justifyContent={"center"}>
                      <MdOutlineCheckBoxOutlineBlank size={25} />
                    </Flex>
                  )}
                </Td>

                <Td> {item?.WeekendCycleDays} </Td>

                <Td>
                  {item?.IsFlexibleHour ? (
                    <Flex justifyContent={"center"} textColor={"green.500"}>
                      <FaCheck size={25} />
                    </Flex>
                  ) : (
                    <Flex justifyContent={"center"}>
                      <MdOutlineCheckBoxOutlineBlank size={25} />
                    </Flex>
                  )}
                </Td>

                <Td> {item?.HourPerDay} </Td>

                <Td>
                  {item?.IsActive ? (
                    <Flex justifyContent={"center"} textColor={"green.500"}>
                      <FaCheck size={25} />
                    </Flex>
                  ) : (
                    <Flex justifyContent={"center"}>
                      <MdOutlineCheckBoxOutlineBlank size={25} />
                    </Flex>
                  )}
                </Td>
                <Td>
                  <Link to={`/GroupShiftDetails/${item?.Attend_Leave_GroupID}`}>
                    <Button colorScheme="green" size="sm">
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <span> {t("pages.hr.view")} </span>
                      </Flex>
                    </Button>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          currentPage={currentPageActive}
          setCurrentPage={setCurrentPageActive}
          npage={Math.ceil(GroupShiftList?.length / recordsPerPage)}
          prePage={() => prePage(currentPageActive, setCurrentPageActive)}
          nextPage={() =>
            nextPage(
              currentPageActive,
              setCurrentPageActive,
              Math.ceil(GroupShiftList?.length / recordsPerPage)
            )
          }
        />
      </Box>
    </>
  );
}
