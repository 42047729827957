import React from 'react';
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr, Checkbox, } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';

const TodayAttendanceTable = () => {

    const { t } = useTranslation();


  return (
    <Box
            bg="light"
            overflow="auto"
            borderRadius={4}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)">


            <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                paddingBlock={2}
                p={4}
                borderBottom="1px solid"
                borderColor="border"
                bg="light"
                gap={2}
            >
                <Flex
                    alignItems="center"
                    flexWrap="wrap"
                    textTransform="capitalize"
                    gap={4}
                    color="text"
                    bg="light"
                >
                    <GButton
                        title={t("general.all")}
                        bg="none"
                        isDisabled={false}
                        borderRadius={0}
                        borderColor="primary"
                    />
                </Flex>

                <GButton
                    title={t("general.filter")}
                    border="1px solid"
                    borderColor="border"
                    postfixElement={<FiIcons.FiFilter size={16} />}
                    hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                //onClick={() => setShowFilterCategoriesModal(true)}
                />
            </Flex>


            <Table>
                <Thead>
                    <Tr>
                        <Th>{t("pages.hr.picture")}</Th>
                        <Th>{t("pages.hr.Employee_code")}</Th>
                        <Th>{t("pages.hr.Employee_name")}</Th>
                        <Th>{t("pages.hr.Employee_department")}</Th>
                        <Th>{t("pages.hr.sign_in")}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr
                        
                    >  
                        <Td style={{ textAlign: "center" }}>
                            <img src='https://tse4.mm.bing.net/th?id=OIP.tGZ5kxcOjgqCQz8twG27mQHaFj&pid=Api&P=0&h=220' alt='p' style={{ width: "80px", display: "block", margin: "auto" }}></img>
                        </Td>
                        <Td>ls003</Td>
                        <Td style={{ cursor: "pointer" }} >youssef</Td>
                        <Td>it</Td>
                        <Td>9:30</Td>
                    </Tr>
                </Tbody>
            </Table>



        </Box>
  );
}

export default TodayAttendanceTable;
