import React, { useState } from "react";
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Spinner,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from "react-i18next";
import { CheckIcon, InfoIcon } from "@chakra-ui/icons";
import EditModal from "./models/EditModal";
import ViewModal from "./models/ViewModal";
import Pagination from "components/ui/pagination/Pagination";
import DeleteModal from "./models/DeleteModal";
//

const PositionsListTable = ({
  data,
  classifications,
  departments,
  jobTitles,
  positions,
  isLoading,
  positionDetails,
}) => {
  // console.log(data);
  const { t } = useTranslation();
  const [showViewModal, setShowViewModal] = useState(null);
  const [showEditModal, setShowEditModal] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);

  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const positionsData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );

  if (isLoading) {
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            borderColor="primary"
          />
        </Flex>

        <GButton
          title={t("general.filter")}
          border="1px solid"
          borderColor="border"
          postfixElement={<FiIcons.FiFilter size={16} />}
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          //onClick={() => setShowFilterCategoriesModal(true)}
        />
      </Flex>

      <Table>
        <Thead>
          <Tr>
            <Th>{t("pages.hr.Positions_code")}</Th>
            <Th>{t("pages.hr.Positions_name")}</Th>
            <Th>{t("pages.hr.reporting_to")}</Th>
            <Th>{t("pages.hr.job_title")}</Th>
            <Th>{t("pages.hr.department_name")}</Th>
            <Th>{t("pages.hr.is_active")}</Th>
            <Th>{t("pages.hr.view")}</Th>
            <Th>{t("pages.hr.edit")}</Th>
            <Th>{t("pages.hr.delete")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {positionsData?.map((el, index) => {
            return (
              <Tr key={index}>
                <Td>{el.PositionCode}</Td>
                {}
                <Td>
                  {el.PositionCode} - {jobTitles[el.JobTitleId]?.JobTitleEn}
                </Td>
                <Td>{el.ReportingTo}</Td>
                <Td>{jobTitles[el.JobTitleId]?.JobTitleEn}</Td>
                <Td>{departments[el.DepartmentId]?.DepartmentName_EN}</Td>
                <Td>
                  {el.IsActive ? (
                    <CheckIcon color="green.500" w={5} h={5} />
                  ) : (
                    <InfoIcon color="red.600" w={5} h={5} />
                  )}
                </Td>
                <Td>
                  <Button
                    colorScheme="green"
                    size="sm"
                    onClick={() => setShowViewModal(el?.PositionId)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("pages.hr.view")} </span>
                    </Flex>
                  </Button>
                </Td>
                <Td>
                  <Button
                    colorScheme="telegram"
                    size="sm"
                    onClick={() => setShowEditModal(el?.PositionId)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("pages.hr.edit")} </span>
                    </Flex>
                  </Button>
                </Td>
                <Td>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => setShowDeleteModal(el?.PositionId)}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <span> {t("pages.hr.delete")} </span>
                    </Flex>
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(data?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(data?.length / recordsPerPage)
          )
        }
      />

      {showViewModal && (
        <ViewModal
          onClose={() => setShowViewModal(false)}
          id={showViewModal}
          classifications={classifications}
          departments={departments}
          jobTitles={jobTitles}
          positions={positions}
        />
      )}
      {showEditModal && (
        <EditModal
          id={showEditModal}
          classifications={classifications}
          departments={departments}
          jobTitles={jobTitles}
          positions={positions}
          positionDetails={positionDetails}
          onClose={() => setShowEditModal(false)}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          id={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </Box>
  );
};

export default PositionsListTable;
