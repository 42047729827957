import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
} from "@chakra-ui/react";
import { FaUserCheck, FaUserTimes } from "react-icons/fa";

import { useTranslation } from "react-i18next";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useDispatch } from "react-redux";
import { GetContractList } from "../../services/employeeServices";
import ViewContractModal from "../modal/ViewContractModal";
import { useParams } from "react-router-dom";
import NoDataFound from "components/ui/noDataFound/NoDataFound";

const ContractCompo = ({ contractsList }) => {
  // console.log(contractsList);
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showViewModal, setShowViewModal] = useState(false);

  useEffect(() => {
    dispatch(GetContractList(id));
  }, [dispatch, id]);

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            borderColor="primary"
          />
        </Flex>

        <GButton
          title={t("general.filter")}
          border="1px solid"
          borderColor="border"
          postfixElement={<FiIcons.FiFilter size={16} />}
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          //onClick={() => setShowFilterCategoriesModal(true)}
        />
      </Flex>

      {contractsList?.length > 0 ? (
        <>
          <Table>
            <Thead>
              <Tr bg={"blue.500"}>
                <Th color={"white"}>#</Th>
                <Th color={"white"}>{t("pages.hr.Contract_Id")}</Th>
                <Th color={"white"}>{t("pages.hr.from")}</Th>
                <Th color={"white"}>{t("pages.hr.to")}</Th>
                <Th color={"white"}>{t("pages.hr.active")}</Th>
                <Th color={"white"}>{t("pages.hr.view")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contractsList?.map((el, index) => (
                <Tr key={index}>
                  <Td> {index + 1} </Td>
                  <Td>{el?.ContractId}</Td>
                  <Td> {el?.StartDateofContract?.split("T")[0]} </Td>
                  <Td> {el?.EndDateofContract?.split("T")[0]} </Td>
                  {el?.IsActive ? (
                    <Td
                      color={"green.600"}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <FaUserCheck size={25} />
                    </Td>
                  ) : (
                    <Td
                      color={"red.600"}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <FaUserTimes size={25} />
                    </Td>
                  )}
                  <Td>
                    <Button
                      colorScheme="green"
                      size="sm"
                      onClick={() => setShowViewModal(true)}
                    >
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <span> {t("pages.hr.view")} </span>
                      </Flex>
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      ) : (
        <>
          <Table>
            <Thead>
              <Tr bg={"blue.500"}>
                <Th color={"white"}>{t("pages.hr.permission_id")}</Th>
                <Th color={"white"}>{t("pages.hr.permission_Date")}</Th>
                <Th color={"white"}>{t("pages.hr.permission_Type")}</Th>
                <Th color={"white"}>{t("pages.hr.creation_date")}</Th>
              </Tr>
            </Thead>
          </Table>
          <NoDataFound />
        </>
      )}

      {/* {showViewModal && (
        <ViewContractModal onClose={() => setShowViewModal(false)} />
      )} */}
    </Box>
  );
};

export default ContractCompo;
