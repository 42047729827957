import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  Flex,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  editVacation,
  getOfficialVacationList,
} from "../../services/vacationServices";

export default function EditVacationModal({ onClose, item, classifications }) {
  console.log(item);
  const UserId = localStorage.getItem("userId");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const { handleSubmit, setValue } = useForm();
  // Show DropDown Modal
  const [showSpecificDropDown, setShowSpecificDropDown] = useState(
    item?.ForSpecificClassification
  );
  const handelShowDropDown = () => {
    setShowSpecificDropDown((prev) => !prev);
  };

  const [isAcademicLeave, setIsAcademicLeave] = useState(item?.IsAcademicLeave);
  const handelIsAcademicLeave = () => {
    setIsAcademicLeave((prev) => !prev);
  };
  const [isNonAcademicLeave, setIsNonAcademicLeave] = useState(
    item.IsNonAcademicLeave
  );
  const handelIsNonAcademicLeave = () => {
    setIsNonAcademicLeave((prev) => !prev);
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.edit_vacation")}
        modalContent={
          <Box p={4}>
            <form
              onSubmit={handleSubmit((values) => {
                values.UserId = UserId;
                values.OfficialVacationId = item?.OfficialVacationId;
                values.ForSpecificClassification =
                  showSpecificDropDown === false ? 0 : 1;
                values.EmployeeClassificationId =
                  showSpecificDropDown === false
                    ? null
                    : values.EmployeeClassificationId?.EmployeeClassificationId;

                values.IsAcademicLeave = isAcademicLeave === false ? 0 : 1;
                values.IsNonAcademicLeave =
                  isNonAcademicLeave === false ? 0 : 1;

                console.log(values);
                // if (
                //   showSpecificDropDown &&
                //   values.EmployeeClassificationId === undefined
                // ) {
                //   toast2({ description: t("validation.choose_first") });
                // } else {
                //   dispatch(editVacation(values))
                //     .unwrap()
                //     .then((_) => {
                //       toast({
                //         description: t("pages.hr.vacation_updated"),
                //       });
                //       dispatch(getOfficialVacationList(UserId));
                //       onClose();
                //     });
                // }
              })}
            >
              <Stack spacing={6}>
                <Box>
                  <Checkbox
                    onChange={handelShowDropDown}
                    defaultChecked={showSpecificDropDown}
                  >
                    {t("pages.hr.ForSpecificClassification")}
                  </Checkbox>
                  {showSpecificDropDown && (
                    <MultiSelect
                      isMulti={false}
                      defaultValue={{
                        label:
                          classifications[item?.EmployeeClassificationId]
                            ?.EmployeeClassificationNameEn,
                      }}
                      data={
                        classifications &&
                        classifications?.map((el) => ({
                          ...el,
                          label: el.EmployeeClassificationNameEn,
                          value: el.EmployeeClassificationId,
                        }))
                      }
                      onChange={(e) => setValue("EmployeeClassificationId", e)}
                    />
                  )}
                </Box>
                <Box>
                  <Text> {t("pages.hr.OfficialLeaveCause")} </Text>
                  <Textarea
                    placeholder="Here is a sample placeholder"
                    marginTop={"2"}
                    defaultValue={item?.OfficialVacationCause}
                    onChange={(e) =>
                      setValue("OfficialVacationCause", e.target.value)
                    }
                  />
                </Box>

                <Box>
                  <Text>{t("pages.hr.from_date")}</Text>
                  <Input
                    size="md"
                    type="date"
                    marginTop={"2"}
                    onChange={(e) => setValue("FromDate", e.target.value)}
                    defaultValue={item?.FromDate}
                  />
                </Box>
                <Box>
                  <Text>{t("pages.hr.to_date")}</Text>
                  <Input
                    size="md"
                    type="date"
                    marginTop={"2"}
                    onChange={(e) => setValue("ToDate", e.target.value)}
                    defaultValue={item?.ToDate}
                  />
                </Box>

                <Checkbox
                  onChange={handelIsAcademicLeave}
                  defaultChecked={isAcademicLeave}
                >
                  {t("pages.hr.IsAcademicLeave")}
                </Checkbox>

                <Checkbox
                  onChange={handelIsNonAcademicLeave}
                  defaultChecked={isNonAcademicLeave}
                >
                  {t("pages.hr.IsNonAcademicLeave")}
                </Checkbox>

                <Flex gap={4}>
                  <GButton
                    type="submit"
                    title={t("general.save")}
                    bg="primary"
                    color="light"
                    border="1px solid transparent"
                    hover={{
                      color: "primary",
                      borderColor: "primary",
                      bg: "none",
                    }}
                  />
                  <GButton
                    title={t("general.close")}
                    onClick={onClose}
                    bg="red.600"
                    border="1px solid transparent"
                    color="light"
                    hover={{
                      color: "red.600",
                      borderColor: "red.600",
                      bg: "none",
                    }}
                  />
                </Flex>
              </Stack>
            </form>
          </Box>
        }
      ></GModal>
    </>
  );
}
