import { createSlice } from "@reduxjs/toolkit";
import { getInsuranceOffice } from "../services/generalEmployeeServices";

let initialState = {
  isLoading: false,
  pages: 0,
  errors: null,

  InsuranceOffice: [],

  // Filter data
  filteredData: [],
};

const generalEmployee = createSlice({
  name: "generalEmployee",
  initialState,
  extraReducers: (builder) => {
    /* ----------------------------------  Get Insurance Office Dropdown  ---------------------------------------*/
    builder.addCase(getInsuranceOffice.pending, (state, { payload }) => {
      state.isLoading = true;
      state.errors = null;
    });
    builder.addCase(getInsuranceOffice.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.InsuranceOffice =
        (payload[0] && JSON.parse(payload[0].jsonData)) || null;
      state.errors = null;
    });
    builder.addCase(getInsuranceOffice.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.errors = [{ msg: "something went wrong!" }];
    });
  },
});

export default generalEmployee.reducer;
