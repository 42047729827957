import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Checkbox,
  Flex,
  Input,
  Stack,
  Text,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Spinner,
} from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  editPositionById,
  getPositionDetailsById,
  getPositionsList,
} from "../../services/positionsServices";

export default function EditModal({
  onClose,
  classifications,
  departments,
  jobTitles,
  positions,
  id,
  positionDetails,
}) {
  const UserId = localStorage.getItem("userId");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  // Check Box State
  const [isActive, setIsActive] = useState(positionDetails[0]?.IsActive);
  const handelIsActive = (e) => {
    setIsActive((prev) => !prev);
  };

  const [isSMG, setIsSMG] = useState(positionDetails[0]?.IsSMG);
  const handelIsSMG = (e) => {
    setIsSMG((prev) => !prev);
  };

  const [isAcademicLeave, setIsAcademicLeave] = useState(
    positionDetails[0]?.IsAcademicLeave
  );
  const handelIsAcademicLeave = (e) => {
    setIsAcademicLeave((prev) => !prev);
  };
  const [isFullTime, setIsFullTime] = useState(positionDetails[0]?.IsFullTime);
  const handelIsFullTime = (e) => {
    setIsFullTime((prev) => !prev);
  };

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    dispatch(getPositionDetailsById(id));
    setTimeout(() => {
      setShowContent(true);
    }, 1000);
  }, [dispatch, id]);

  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.position.edit_position_details")}
        modalContent={
          showContent ? (
            <Box p={4}>
              <form
                onSubmit={handleSubmit((values) => {
                  values.UserId = UserId;
                  values.PositionId = positionDetails[0]?.PositionId;
                  //-----------------------------------------------------------------
                  values.ReportingToPositionId =
                    values.ReportingTo?.PositionId ||
                    positionDetails[0]?.ReportingToPositionId;
                  values.ReportingTo =
                    values.ReportingTo?.PositionCode ||
                    positionDetails[0]?.PositionCode;

                  values.PositionCode =
                    values?.PositionCode || positionDetails[0]?.PositionCode;
                  //-------------------------------------------------------

                  values.DepartmentId =
                    values?.DepartmentId?.DepartmentId ||
                    positionDetails[0]?.DepartmentId;

                  values.JobTitleId =
                    values?.JobTitleId?.JobTitleId ||
                    positionDetails[0]?.JobTitleId;

                  values.EmployeeClassificationId =
                    values?.EmployeeClassificationId
                      ?.EmployeeClassificationId ||
                    positionDetails[0]?.EmployeeClassificationId;

                  //-------------------------------------------------------
                  values.IsActive = isActive ? true : false;
                  values.IsSMG = isSMG ? true : false;
                  values.IsAcademicLeave = isAcademicLeave ? true : false;
                  values.IsFullTime = isFullTime ? true : false;
                  // //-------------------------------------------------------
                  // values.IsActive = isActive ? 1 : 0;
                  // values.IsSMG = isSMG ? 1 : 0;
                  // values.IsAcademicLeave = isAcademicLeave ? 1 : 0;
                  // values.IsFullTime = isFullTime ? 1 : 0;
                  if (
                    values.DepartmentId === undefined &&
                    values.EmployeeClassificationId === undefined &&
                    values.JobTitleId === undefined &&
                    values.ReportingTo === undefined
                  ) {
                    toast2({
                      description: t("pages.hr.hr_validation.select_item"),
                    });
                  } else {
                    dispatch(editPositionById(values))
                      .unwrap()
                      .then((_) => {
                        toast({
                          description: t("pages.hr.edit_position_sucessfuly"),
                        });
                        dispatch(getPositionsList());
                        onClose();
                      });
                  }
                })}
              >
                <Stack spacing={6}>
                  <FormControl isInvalid={errors.PositionCode}>
                    <FormLabel
                      htmlFor="PositionCode"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.position.position_code")}
                    </FormLabel>
                    <Controller
                      name="PositionCode"
                      control={control}
                      defaultValue={positionDetails[0]?.PositionCode}
                      rules={{
                        required: t("validation.required"),
                        minLength: {
                          value: 3,
                          message: t("validation.name_long_2"),
                        },
                        maxLength: {
                          value: 10,
                          message: t("validation.name_long_25"),
                        },
                        validate: {
                          noOnlySpaces: (value) =>
                            !isOnlySpaces(value) ||
                            t("validation.no_only_spaces"),
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.position.position_code")}
                          onChange={(e) => {
                            handleInputChange(e, "PositionCode");
                          }}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.PositionCode && errors.PositionCode.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Box>
                    <Text>{t("pages.hr.position.job_title")}</Text>
                    <MultiSelect
                      isMulti={false}
                      data={
                        jobTitles &&
                        jobTitles?.map((el) => ({
                          ...el,
                          label: el.JobTitleEn,
                          value: el.JobTitleId,
                        }))
                      }
                      defaultValue={{
                        label:
                          jobTitles[positionDetails[0]?.JobTitleId - 1]
                            ?.JobTitleEn,
                        value:
                          jobTitles[positionDetails[0]?.JobTitleId]?.JobTitleEn,
                      }}
                      onChange={(e) => setValue("JobTitleId", e)}
                    />
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.position.position_department")}</Text>
                    <MultiSelect
                      isMulti={false}
                      data={
                        departments &&
                        departments?.map((el) => ({
                          ...el,
                          label: el.DepartmentName_EN,
                          value: el.DepartmentId,
                        }))
                      }
                      defaultValue={{
                        label:
                          departments[positionDetails[0]?.DepartmentId - 1]
                            ?.DepartmentName_EN,
                      }}
                      onChange={(e) => setValue("DepartmentId", e)}
                    />
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.Classification")}</Text>
                    <MultiSelect
                      isMulti={false}
                      data={
                        classifications &&
                        classifications?.map((el) => ({
                          ...el,
                          label: el.EmployeeClassificationNameEn,
                          value: el.EmployeeClassificationId,
                        }))
                      }
                      defaultValue={{
                        label:
                          classifications[
                            positionDetails[0]?.EmployeeClassificationId - 1
                          ]?.EmployeeClassificationNameEn,
                        value:
                          classifications[
                            positionDetails[0]?.EmployeeClassificationId
                          ]?.EmployeeClassificationId,
                      }}
                      onChange={(e) => setValue("EmployeeClassificationId", e)}
                    />
                  </Box>

                  <Box>
                    <Text>{t("pages.hr.position.reporting_to")}</Text>
                    <MultiSelect
                      isMulti={false}
                      data={
                        positions &&
                        positions?.map((el) => ({
                          ...el,
                          label: el.Reportingto,
                          value: el,
                        }))
                      }
                      defaultValue={{
                        label:
                          positions[
                            positionDetails[0]?.ReportingToPositionId - 1
                          ]?.Reportingto,
                        value:
                          positions[positionDetails[0]?.ReportingToPositionId]
                            ?.Reportingto,
                      }}
                      onChange={(e) => setValue("ReportingTo", e)}
                    />
                  </Box>

                  <Checkbox
                    colorScheme="green"
                    defaultChecked={positionDetails[0]?.IsAcademicLeave}
                    onChange={handelIsAcademicLeave}
                  >
                    {t("pages.hr.IsAcademicLeave")}
                  </Checkbox>

                  <Checkbox
                    colorScheme="green"
                    defaultChecked={positionDetails[0]?.IsFullTime}
                    onChange={handelIsFullTime}
                  >
                    {t("pages.hr.position.isFullTime")}
                  </Checkbox>

                  <Checkbox
                    colorScheme="green"
                    defaultChecked={positionDetails[0]?.IsActive}
                    onChange={handelIsActive}
                  >
                    {t("pages.hr.is_active")}
                  </Checkbox>

                  <Checkbox
                    colorScheme="green"
                    defaultChecked={positionDetails[0]?.IsSMG}
                    onChange={handelIsSMG}
                  >
                    {t("pages.hr.position.is_smg")}
                  </Checkbox>

                  <Flex gap={4}>
                    <GButton
                      type="submit"
                      title={t("general.save")}
                      bg="primary"
                      color="light"
                      border="1px solid transparent"
                      hover={{
                        color: "primary",
                        borderColor: "primary",
                        bg: "none",
                      }}
                    />
                    <GButton
                      title={t("general.close")}
                      onClick={onClose}
                      bg="red.600"
                      border="1px solid transparent"
                      color="light"
                      hover={{
                        color: "red.600",
                        borderColor: "red.600",
                        bg: "none",
                      }}
                    />
                  </Flex>
                </Stack>
              </form>
            </Box>
          ) : (
            <Flex bg="light" p={4} justifyContent="center">
              <Spinner />
            </Flex>
          )
        }
      ></GModal>
    </>
  );
}
