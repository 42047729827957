import React from 'react';
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr, Checkbox, } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { useTranslation } from 'react-i18next';

const PayRollListTable = () => {


  const { t } = useTranslation();

    const dumbData = [{}, {}, {}, {}, {}, {}, {}, {}, {}]


  return (
    <Box
            bg="light"
            overflow="auto"
            borderRadius={4}
            boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)">


            <Flex
                flexWrap="wrap"
                justifyContent="space-between"
                paddingBlock={2}
                p={4}
                borderBottom="1px solid"
                borderColor="border"
                bg="light"
                gap={2}
            >
                <Flex
                    alignItems="center"
                    flexWrap="wrap"
                    textTransform="capitalize"
                    gap={4}
                    color="text"
                    bg="light"
                >
                    <GButton
                        title={t("general.all")}
                        bg="none"
                        isDisabled={false}
                        borderRadius={0}
                        borderColor="primary"
                    />
                </Flex>

                <GButton
                    title={t("general.filter")}
                    border="1px solid"
                    borderColor="border"
                    postfixElement={<FiIcons.FiFilter size={16} />}
                    hover={{ bg: "primary", borderColor: "primary", color: "light" }}
                //onClick={() => setShowFilterCategoriesModal(true)}
                />
            </Flex>


            <Table>
                <Thead>
                    <Tr>
                        <Th>{t("pages.hr.year")}</Th>
                        <Th>{t("pages.hr.month")}</Th>
                        <Th>{t("pages.hr.status")}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        dumbData.map((el,index) => {
                            return (
                                <Tr >
                                    <Td>2019</Td>
                                    <Td>JAN</Td>
                                    <Td>CLOSED</Td>
                                </Tr>
                            )
                        })
                    }

                </Tbody>
            </Table>



        </Box>
  );
}

export default PayRollListTable;
