import React, { useEffect } from "react";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import EmployeesTabel from "../components/EmployeesTabel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeList } from "../services/employeeServices";

const EmployeesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.employeeSlice);

  useEffect(() => {
    if (data?.employeeList.length <= 0) {
      dispatch(getEmployeeList());
    }
  }, [data?.employeeList, dispatch]);

  if (data?.isLoading) {
    return (
      <Flex bg="light" p={4} justifyContent="center">
        <Spinner />
      </Flex>
    );
  }
  return (
    <div>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.Employees")}
          </Text>

          <Button
            type="button"
            bg="primary"
            color="light"
            textTransform="capitalize"
            fontSize="1rem"
            onClick={() => navigate("/AddEmployee")}
          >
            <Flex alignItems="center" gap={1}>
              {t("pages.hr.Add_Employee")}
            </Flex>
          </Button>
        </Flex>
      </Box>

      <EmployeesTabel data={data?.employeeList} />
    </div>
  );
};

export default EmployeesPage;


