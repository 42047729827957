import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdWorkHistory } from "react-icons/md";
import { LuNetwork } from "react-icons/lu";
import { Link } from "react-router-dom";
import routes from "navigations/routes";

const AttendanceSettingsPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.Attendance_shift_master_codeing")}
          </Text>

          <Flex gap={2}></Flex>
        </Flex>
      </Box>

      <Box
        bg="light"
        overflow="auto"
        borderRadius={4}
        boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          paddingBlock={2}
          p={4}
          borderBottom="1px solid"
          borderColor="border"
          bg="light"
          gap={2}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            textTransform="capitalize"
            gap={4}
            color="text"
            bg="light"
          >
            <Link to={routes.GroupShift}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                bg={"green.500"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"white"}
                borderRadius={5}
                gap={2}
              >
                <MdWorkHistory size={80} />
                <Text fontSize={"2xl"}> {t("pages.hr.Group_shift")} </Text>
              </Box>
            </Link>

            <Link to={routes.TimeShift}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                bg={"blue.700"}
                paddingY={5}
                paddingX={10}
                justifyContent={"center"}
                alignItems={"center"}
                color={"white"}
                borderRadius={5}
                gap={2}
              >
                <LuNetwork size={80} />
                <Text fontSize={"2xl"}> {t("pages.hr.time_shift")} </Text>
              </Box>
            </Link>
          </Flex>
        </Flex>
      </Box>
    </div>
  );
};

export default AttendanceSettingsPage;
