import React, { useEffect } from "react";
import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import * as FiIcons from "react-icons/fi";
import GButton from "components/ui/button/Button";
import { GetEmployeeVacations } from "../../services/employeeServices";
import { useDispatch } from "react-redux";
import NoDataFound from "components/ui/noDataFound/NoDataFound";

export default function LeaveOrdersCompo({ id, employeeVacationsList }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetEmployeeVacations(id));
  }, [dispatch, id]);

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        paddingBlock={2}
        p={4}
        borderBottom="1px solid"
        borderColor="border"
        bg="light"
        gap={2}
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          textTransform="capitalize"
          gap={4}
          color="text"
          bg="light"
        >
          <GButton
            title={t("general.all")}
            bg="none"
            isDisabled={false}
            borderRadius={0}
            borderColor="primary"
          />
        </Flex>

        <GButton
          title={t("general.filter")}
          border="1px solid"
          borderColor="border"
          postfixElement={<FiIcons.FiFilter size={16} />}
          hover={{ bg: "primary", borderColor: "primary", color: "light" }}
          //onClick={() => setShowFilterCategoriesModal(true)}
        />
      </Flex>

      {employeeVacationsList?.length > 0 ? (
        <>
          <Table>
            <Thead>
              <Tr bg={"blue.500"}>
                <Th color={"white"}>{t("pages.hr.leave_type")}</Th>
                <Th color={"white"}>{t("pages.hr.from")}</Th>
                <Th color={"white"}>{t("pages.hr.to")}</Th>
                <Th color={"white"}>{t("pages.hr.days")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {employeeVacationsList?.map((el, index) => (
                <Tr key={index}>
                  <Td>{el?.LeaveType}</Td>
                  <Td>{el?.FromDate}</Td>
                  <Td>{el?.ToDate}</Td>
                  <Td>{el?.count}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      ) : (
        <>
          <Table>
            <Thead>
              <Tr bg={"blue.500"}>
                <Th color={"white"}>{t("pages.hr.permission_id")}</Th>
                <Th color={"white"}>{t("pages.hr.permission_Date")}</Th>
                <Th color={"white"}>{t("pages.hr.permission_Type")}</Th>
                <Th color={"white"}>{t("pages.hr.creation_date")}</Th>
              </Tr>
            </Thead>
          </Table>
          <NoDataFound />
        </>
      )}
    </Box>
  );
}
